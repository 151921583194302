function LocationExtensionsString() {

	// http://stackoverflow.com/a/901144/1501375
	if (typeof Location.prototype.getHashParameterByName != 'function') {

		Location.prototype.getHashParameterByName = function (name) {
			name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
			var regex = new RegExp("[#&]" + name + "=([^&?#]*)"),
				results = regex.exec(location.hash);
			return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
		};
	}

	if (typeof Location.prototype.getSearchParameterByName != 'function') {

		Location.prototype.getSearchParameterByName = function (name) {
			name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
			var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
				results = regex.exec(location.search);
			return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
		};
	}

}

LocationExtensionsString();


