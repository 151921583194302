import ko from "knockout";

function MainMenuViewModel(params) {
	var self = this;
	self.V = "GLOBALS_PACKAGE_VERSION";
    self.B = "GLOBALS_BUILD_TIMESTAMP";
}

import template from "./main-menu.html?raw";
import * as style from "./main-menu.scss";

export default {
	viewModel: MainMenuViewModel,
	template,
	style
};