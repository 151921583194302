import ko from "knockout";
import { mainMessageBus } from "@/helper/mainMessageBus.js";

function MainErrorViewModel(params) {
	var self = this;
	self.mainMessageBus = mainMessageBus;

	self.currentMessage = ko.pureComputed(function () {
		let c = self.mainMessageBus();
		if (c && c.statusCode != 401)
			return c;
		else
			return null;
	});

	self.dismissMessage = function () {
		var currentMessage = self.currentMessage();
		self.mainMessageBus(null);
		if (currentMessage && currentMessage.onOk)
			currentMessage.onOk();
	};

	self.messagePresent = ko.pureComputed(function () {
		var currentMessage = self.currentMessage();
		return (currentMessage != null);
	});
	self.messageTitle = ko.pureComputed(function () {
		var currentMessage = self.currentMessage();
		if (currentMessage == null) return null;
		return currentMessage.messageTitle;
	});
	self.messageText = ko.pureComputed(function () {
		var currentMessage = self.currentMessage();
		if (currentMessage == null) return null;
		return currentMessage.messageText;
	});
	self.messageDetail = ko.pureComputed(function () {
		var currentMessage = self.currentMessage();
		if (currentMessage == null) return null;
		return currentMessage.messageDetail;
	});
	self.messageCanBeDismissed = ko.pureComputed(function () {
		var currentMessage = self.currentMessage();
		if (currentMessage == null) return false;
		return Boolean(currentMessage.canBeDismissed);
	});


}

import template from "./main-error.html?raw";
import * as style from "./main-error.scss";

export default {
	viewModel: MainErrorViewModel,
	template,
	style
};

