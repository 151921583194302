import ko from "knockout";

function MainUpdateViewModel(params) {
    var self = this;

    self.updateReady = ko.observable(false);

    self.checkForUpdate = function () {
        if (window.applicationCache && window.applicationCache.status == window.applicationCache.IDLE) {
            try {
                window.applicationCache.update();
            } catch (e) {

            }
        }
    };

    self.onUpdateReady = function () {
        if (window.applicationCache && window.applicationCache.status == window.applicationCache.UPDATEREADY) {
            window.applicationCache.swapCache();
            self.updateReady(true);
        }
    };

    self.rejectUpdate = function () {
        self.updateReady(false);
    };

    self.acceptUpdate = function () {
        window.location.reload();
    };

    // Setup
    if (window.applicationCache) {
        window.applicationCache.addEventListener('updateready', self.onUpdateReady, false);
        setInterval(self.checkForUpdate, 60000);
    }

}

import template from "./main-update.html?raw";
import * as style from "./main-update.scss";

export default {
    viewModel: MainUpdateViewModel,
    template,
    style
};

