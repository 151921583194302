import template from "./floating-button.html?raw";
import * as style from "./floating-button.scss";

function FloatingButtonViewModel(params) {
	var self = this;
	self.onClick = params.onClick;
	self.isActive = params.isActive;
	self.type = params.type;
}

export default {
	viewModel: FloatingButtonViewModel,
	template,
	style
};
