import template from "./messagebox.html?raw";
import * as style from "./messagebox.scss";

function MessageBoxViewModel(params) {
    var self = this;

    // Configuration
    self.caption = params.caption;
    self.text = params.text;
    self.detailText = params.detailText;

    // Buttons
    self.onYes = params.onYes;
    self.onNo = params.onNo;
    self.onCancel = params.onCancel;
    self.onOk = params.onOk;

}

export default {
    viewModel: MessageBoxViewModel,
    template,
    style
};