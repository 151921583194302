import { fnAjax } from "./backendAjaxService.js";

export const configService = {
    getAmazonAccounts: () => {
        return new Promise((resolve, reject) => {
            fnAjax("api/config/amazonAccounts", {}, reject, function (data) {
                if (data) {
                    resolve(data);
                } else {
                    reject();
                }
            })
        });
    }
};