import ko from "knockout";
import { backendService } from "@/services/api_v2_liste.js";
import { configService } from "@/services/configService.js";

import StatusWidget from "./pfast-widget-job-list-status/job-list-status.js";
ko.components.register('pfast-widget-job-list-status', StatusWidget);

import SingleModal from "./pfast-widget-job-list-single/job-list-single.js";
ko.components.register('pfast-widget-job-list-single', SingleModal);

import NewModal from "./pfast-widget-job-list-new/job-list-new.js";
ko.components.register('pfast-widget-job-list-new', NewModal);

function JobListItemModel(params, amazonKonten) {
	var self = this;
	self.jobId = ko.observable(params.id);
	self.jobName = ko.observable(params.jobName);
	self.numTotal = ko.observable(params.numTotal);
	self.numSuccess = ko.observable(params.numSuccess);
	self.numNotFound = ko.observable(params.numNotFound);
	self.timeStamp = ko.observable(params.timeStamp);
	self.excelReady = ko.observable(params.excelReady);
	self.identifierType = ko.observable(params.identifierType);
	self.queryType = ko.observable(params.queryType);
	self.statusText = ko.observable(params.statusText);
    self.amazonKontoAmpel = ko.computed(function() {
        return amazonKonten()
            ?.find(k => k.sellerId == params.amazonKontoAmpel)
            ?.accountName
            ?? params.amazonKontoAmpel;
    })
}

function JobListViewModel(params) {
	var self = this;

	// Job Liste
	self.jobList = ko.observableArray();
	self.jobList.sorted = ko.computed(function () {
		return self.jobList().sort(function (a, b) {
			return (a.timeStamp() < b.timeStamp()) ? 1 : -1;
		});;
	});
	self.jobList.getEntryByJobId = function (jobId) {
		var l = self.jobList();
		for (var i = 0; i < l.length; i++) {
			var n = l[i].jobId();
			if (n == jobId) return l[i];
		}
	};
	self.jobList.refresh = function () {
		var fnFinally = function () {
			setTimeout(self.jobList.refresh, 5000);
		};
		var fnError = function (error) {
			console.log("jobList.refresh error");
			console.log(error);
			fnFinally();
		};
		var fnSuccess = function (getBatchJobStatusListQueryResult) {
            self.jobList(
                getBatchJobStatusListQueryResult
                    ?.results
                    ?.map(item => new JobListItemModel(item, self.amazonKonten))
            );
			fnFinally();
		};

		// List nur aktualisieren, wenn exklusiv sichtbar
		if (self.menu.singleJob.isActive() || self.menu.listJob.isActive())
			fnFinally();
		else
			backendService.listStoredJobs({}, fnError, fnSuccess);
	};

	// Löschfunktion
	self.deleteJob = {
		currentJob: ko.observable(null),
		isActive: ko.pureComputed(function () {
			return Boolean(self.deleteJob.currentJob() != null);
		}),
		activate: function (job) {
			self.deleteJob.currentJob(job);
		},
		deactivate: function () {
			self.deleteJob.currentJob(null);
		},
		afterExecute: function () {
			self.deleteJob.deactivate();
			self.jobList.refresh();
		},
		execute: function () {
			var job = self.deleteJob.currentJob();
			if (job != null) {
				var cmd = { jobId: job.jobId() };
				backendService.deleteStoredJob(cmd, self.deleteJob.afterExecute, self.deleteJob.afterExecute);
			}
		}
	}

	// Downloadfunktion
	self.downloadExcel = function (job) {
		var cmd = {
			jobId: job.jobId(),
			fileName: job.jobName() + ".xlsx"
		};
		var fnDoNothing = function () { };
		backendService.getStoredJobFile(cmd, fnDoNothing, fnDoNothing);
	};

	// Menü
	self.menu = {
		singleJob: {
			isActive: ko.observable(false),
			externalId: null,
			activate: function (externalId) {
				self.menu.singleJob.externalId = (typeof (externalId) == "string") ? externalId : null;
				self.menu.singleJob.isActive(true);
			},
			deactivate: function () {
				self.menu.singleJob.externalId = null;
				self.menu.singleJob.isActive(false);
			}
		},
		listJob: {
			isActive: ko.observable(false),
			activate: function () {
				self.menu.listJob.isActive(true);
			},
			deactivate: function () {
				self.menu.listJob.isActive(false);
			}
		}
	}

	// Überwache die Location auf incoming EAN
	var queryEAN = location.search.match(/\?ean=(.*)/);
	if (queryEAN && queryEAN.length == 2) {
		history.replaceState(history.state, "", window.location.origin + window.location.pathname);
		self.menu.singleJob.activate(queryEAN[1]);
	}

    // Amazonkonten abfragen
    self.amazonKonten = ko.observableArray();
    configService.getAmazonAccounts()
        .then(amazonAccounts => {
            self.amazonKonten.push(...(amazonAccounts?.items ?? []));
        })
        .finally(() => {
            // Initial Jobliste laden
            self.jobList.refresh();
        });

}

import template from "./view-job-list.html?raw";
import * as style from "./view-job-list.scss";

export default {
	viewModel: JobListViewModel,
	template,
	style
};
