import ko from "knockout";

export var mainMessageBus = ko.observable();

mainMessageBus.fromAjaxError = function (error) {
	if (error) {

		// Error 0 wird einfach ignoriert
		if (error.status == 0) {
			return;
		}

		// Backend nicht verfügbar:
		if (error.status == 503 || error.status == 504) {
			mainMessageBus({
				statusCode: error.status,
				messageTitle: 'Server nicht verfügbar',
				messageText: 'Aktuell ist der Server leider nicht erreichbar. Bitte versuchen Sie es später noch einmal.',
				canBeDismissed: true
			});
			return;
		}

		// Berechtigungen nicht ausreichend
		if (error.status == 403) {
			mainMessageBus({
				statusCode: error.status,
				messageTitle: 'Berechtigungen nicht ausreichend',
				messageText: 'Ihrem Benutzerkonto sind nicht genügend Berechtigungen zugeordnet um diese Aktion auszuführen.',
				canBeDismissed: true
			});
			return;
		}

		// Login notwendig
		if (error.status == 401) {
			mainMessageBus({
				statusCode: error.status
			});
			return;
		}

		// Sonstiges
		mainMessageBus({
			statusCode: error.status,
			messageTitle: 'Ohje,',
			messageText: 'da ist leider etwas schief gelaufen. ',
			messageDetail: JSON.stringify(error, null, 4),
			canBeDismissed: true
		});

	}
};