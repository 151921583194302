import ko from "knockout";
import { read, utils } from "xlsx";
import { backendService } from "@/services/api_v2_liste.js";
import { localUserSettings } from "@/helper/localUserSettings.js";
import { mainMessageBus } from "@/helper/mainMessageBus.js";

function JobListNewWidgetViewModel(params) {

	var self = this;
	self.onClose = params.onClose;

	// Aktueller Schritt
	self.currentStep = ko.observable(1);

	// Wertelisten
	self.queryTypeList = [
		{ value: "USED", text: "gebraucht" },
		{ value: "NEW", text: "neu" }
	];
	self.flagPaletteList = [
		{ value: true, text: "Ja" },
		{ value: false, text: "Nein" }
	];

	// Eingabewerte 
	self.jobName = ko.pureComputed(function () {
		var x = self.importedFileData();
		return (x != null) ? x.fileName : null;
	}).extend({ regExAllowedPattern: "[a-zA-Z0-9 _äöüÄÖÜß\.,\-]*" });
	self.selectedQueryType = ko.observable(self.queryTypeList[0]);
	self.defaultZustand = ko.observable(2);
	self.flagPalette = ko.observable(false);
    self.flagPaletteDisplay = ko.pureComputed(function () {
		return self.flagPaletteList.find(x => x.value == self.flagPalette())?.text
	});

	// Eingabedatei lesen
	self.importedFileData = ko.observable();
	self.importedFileName = ko.pureComputed(function () {
		var ifd = self.importedFileData();
		return ifd != null ? ifd.fileName : null;
	});
	self.importedFileLineCount = ko.pureComputed(function () {
		var ifd = self.importedFileData();
		return ifd != null ? ifd.inputLines.length : null;
	});
	self.importFileRunning = ko.observable(false);
	self.onImportXLSXFile = function (vm, evt) {

		self.importFileRunning(true);
		self.importedFileData(null);
		var importData = [];
		var importDataCountFiles = 0;

		// Process Data Line
		var fnProcessDataLine = function (info, dataLineArray) {

			var fnSave = function (f) {
				return f != null ? dataLineArray[f] : null;
			};

			var fnSaveNumber = function (f) {
				var x = fnSave(f);
				if (typeof x == 'number')
					return x;
				if (typeof x == 'string') {
					var y = Number(x.replace(',', '.'));
					if (isNaN(y) == false) return y;
				}
				return null;
			};

			return {
				id: fnSave(info.columnIndexId),
				ek: fnSaveNumber(info.columnIndexEk),
				bestand: fnSaveNumber(info.columnIndexBestand),
				zustand: fnSave(info.columnIndexZustand),
				textzusatz: fnSave(info.columnIndexTextZusatz),
				kkpreis: fnSaveNumber(info.columnIndexKKPreis),
				sourceColumns: dataLineArray
			};

		};

		// Process Column Captions
		var fnProcessColumnCaptions = function (firstLineArray) {

			var fnFindIndex = function (columnMatchers) {
				for (var i = 0; i < columnMatchers.length; i++) {
					var cm = (columnMatchers[i] || "").toLowerCase().trim();
					for (var j = 0; j < firstLineArray.length; j++) {
						var jm = (firstLineArray[j] || "").toLowerCase().trim();
						if (jm == cm) return j;
					}
				}
				return null;
			}

			var keys = {
				ID_EAN: ['EAN', 'EAN_dsd', 'EANNummer'],
				ID_ISBN: ['ISBN'],
				ID_ASIN: ['ASIN'],
				EK: ['EK', 'NEP', 'EK neu inkl. MwSt.'],
				BESTAND: ["Bestand", "Menge", "Bestand ATB", "Bestand ABK", "Bestand ABB", "Bestand GLB", "Bestand ca.", "Quantity Available"],
				ZUSTAND: ["Zustand"],
				TEXTZUSATZ: ["Text-Zusatz", "Zusatztext", "Bibliotext"],
				KKPREIS: ["KK Preis"]
			};

			// Rückgabestruktur
			var ret = {
				columnIndexId: fnFindIndex([].concat(keys.ID_EAN, keys.ID_ISBN, keys.ID_ASIN)),
				columnIndexEk: fnFindIndex(keys.EK),
				columnIndexBestand: fnFindIndex(keys.BESTAND),
				columnIndexZustand: fnFindIndex(keys.ZUSTAND),
				columnIndexTextZusatz: fnFindIndex(keys.TEXTZUSATZ),
				columnIndexKKPreis: fnFindIndex(keys.KKPREIS)
			};

			// ID Typ aus Spalten ableiten
			Object.keys(keys).forEach(function (key) {
				if (key.substr(0, 3) == 'ID_') {
					if (fnFindIndex(keys[key]) != null) {
						ret.idType = key.substr(3);
					}
				}
			});

			// Rückgabe
			return ret;
		};

		// Validate Processed Line
		var fnValidateDataLine = function (dataLine) {

			// Prüfe Pflichtfelder
			if (dataLine == null)
				return null;
			if (dataLine.id == null)
				return null;
			if (dataLine.ek == null)
				dataLine.ek = 0.0;

			// Setze sinnvolle Defaults
			if (dataLine.sourceColumns == null)
				dataLine.sourceColumns = [];

			// Prüfe Länge bei Textfelder - max 512 Zeichen
			if (dataLine.textzusatz != null && dataLine.textzusatz.length > 512)
				dataLine.textzusatz = dataLine.textzusatz.substr(0, 512);
			for (var x = 0; x < dataLine.sourceColumns.length; x++) {
				if (dataLine.sourceColumns[x] != null && dataLine.sourceColumns[x].length > 512)
					dataLine.sourceColumns[x] = dataLine.sourceColumns[x].substr(0, 512);
			}

			return dataLine;
		};

		// read Excel
		var fnProcessInputXLSX = function (fileContent) {

			// XLSX öffnen
			var workbook = read(fileContent, { type: 'binary' });
			var worksheet = workbook.Sheets[workbook.SheetNames[0]];

			// Referenzbereich ermitteln["!ref"]
			var workSheetRange = utils.decode_range(worksheet["!ref"])

			// Helper
			var fnReadCellValue = function (c, r) {
				var cellName = utils.encode_cell({ c: c, r: r });
				var cellReference = worksheet[cellName];
				var cellValue = cellReference != null ? cellReference.v : null;
				return cellValue;
			}

			// Rückgabeobjekt vorbereiten
			var ret = {
				sourceColumns: [],
				inputLines: [],
				idType: null
			};

			// In der ersten Zeile sind die Spaltenüberschriften 
			for (var x = workSheetRange.s.c; x <= workSheetRange.e.c; x++) {
				ret.sourceColumns.push(fnReadCellValue(x, workSheetRange.s.r));
			}

			// Spaltenanalyse und Automapping
			var columnInfo = fnProcessColumnCaptions(ret.sourceColumns);
			if (columnInfo == null || columnInfo.idType == null) return;
			ret.idType = columnInfo.idType;

			// In den Folgezeilen sind nun die Werte enthalten
			for (var y = workSheetRange.s.r + 1; y <= workSheetRange.e.r; y++) {
				var inputLine = [];
				for (var x = workSheetRange.s.c; x <= workSheetRange.e.c; x++) {
					inputLine.push(fnReadCellValue(x, y));
				}
				var processedLine = fnValidateDataLine(fnProcessDataLine(columnInfo, inputLine));
				if (processedLine != null) {
					ret.inputLines.push(processedLine);
				}
			}

			// Fertig
			return ret;

		}

		// Single Upload
		var fnUploadFile = function (file) {

			var fnCheckDone = function () {
				// all files imported?
				importDataCountFiles--;
				if (importDataCountFiles == 0) {
					self.onImportXLSXFileFinished(importData)
				}
			}

			if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {

				var fnProcessLoadedFile = function (e) {
					// conversion arraybuffer -> bStr
					var data = new Uint8Array(e.target.result);
					var arr = new Array();
					for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
					var bstr = arr.join("");

					// conversion bStr -> Excel -> int. Structure
					var fileContent = fnProcessInputXLSX(bstr);
					if (fileContent) {
						fileContent.fileName = file.name.substr(0, file.name.lastIndexOf("."));
						importData.push(fileContent);
					}
					fnCheckDone();
				};

				var reader = new FileReader();
				reader.onloadend = fnProcessLoadedFile;
				reader.readAsArrayBuffer(file);

			} else {
				fnCheckDone();
			}
		};

		// Handle Event
		evt.stopPropagation();
		evt.preventDefault();
		if (evt.target.files && evt.target.files.length > 0) {
			importDataCountFiles = evt.target.files.length
			for (var i = 0; i < evt.target.files.length; i++) {
				fnUploadFile(evt.target.files[i])
			}
		}

	};
	self.onImportXLSXFileFinished = function (importData) {
		if (importData && importData.length > 0) {
			self.importedFileData(importData[0]);
		} else {
			mainMessageBus({
				messageTitle: 'Ohoh',
				messageText: 'Es konnten keine Daten importiert werden. Ist etwas mit der Eingabedatei nicht in Ordnung?',
				canBeDismissed: true
			});
		}
		self.importFileRunning(false);
	};
	self.canStartRemoteJob = ko.pureComputed(function () {
		var ifd = self.importedFileData();
		if (ifd == null) return false;
		if (ifd.fileName == null) return false;
		if (ifd.inputLines == null) return false;
		if (ifd.inputLines.length == 0) return false;
		return true;
	});
	self.onStartRemoteJob = function () {

		if (self.canStartRemoteJob() == false)
			return;
		self.currentStep(3);

		var cmd = {
			jobName: self.jobName(),
			queryType: self.selectedQueryType(),
			identifierType: self.importedFileData().idType,
			flagPalettenAuswertung: Boolean(self.flagPalette()),
			flagExcelErzeugen: true,
			sourceColumnCaptions: self.importedFileData().sourceColumns,
			inputLines: []
		};

		self.importedFileData().inputLines.forEach(function (l) {
			if (!l.zustand)
				l.zustand = self.defaultZustand();
			cmd.inputLines.push(l);
		});

		// Bei Palettenauswertung sollen Bestandsmengen nach ID aggregiert werden
		if (cmd.flagPalettenAuswertung == true) {
			var allGroups = {};
			cmd.inputLines.forEach(function (l) {
				var group = allGroups[l.id];
				if (!group) {
					allGroups[l.id] = l;
				} else {
					allGroups[l.id].bestand += l.bestand;
				}
			});
			cmd.inputLines = Object.keys(allGroups).map(function (key) { return allGroups[key]; });
		}

		var onError = function (error) {
			console.log(error);
			self.currentStep(5);
		};
		var onSuccess = function () {
			self.currentStep(4);
		};
		backendService.processJobAsync(cmd, onError, onSuccess);
	}

	// Einstellungen laden
	localUserSettings.load();
	var q1 = localUserSettings.getListJobDefaultQueryTypeId();
	q1 = (q1 == null) ? self.queryTypeList[0] : q1;
	self.selectedQueryType(q1);

	// Einstellungen speichern
	self.selectedQueryType.subscribe(function (newValue) {
		localUserSettings.load();
		localUserSettings.setListJobDefaultQueryTypeId(newValue);
		localUserSettings.save();
	});

}

import template from "./job-list-new.html?raw";
import * as style from "./job-list-new.scss";

export default {
	viewModel: JobListNewWidgetViewModel,
	template,
	style
};
