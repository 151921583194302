import ko from "knockout";

function JobListStatusWidgetViewModel(params) {
	var self = this;

	// external observables
	self.jobId = params.job.jobId;
	self.jobName = params.job.jobName;
	self.numTotal = params.job.numTotal;
	self.numSuccess = params.job.numSuccess;
	self.numNotFound = params.job.numNotFound;
	self.timeStamp = params.job.timeStamp;
	self.excelReady = params.job.excelReady;
	self.identifierType = params.job.identifierType;
	self.queryType = params.job.queryType;
	self.statusText = params.job.statusText;
    self.amazonKontoAmpel = params.job.amazonKontoAmpel;
    
	// zustand für anzeige
	self.jobStatusClass = ko.pureComputed(function () {
		return self.excelReady() ? 'done' : 'wip';
	});

	// Aktionen
	self.deleteJob = params.deleteJob;
	self.downloadExcel = params.downloadExcel;

}

import template from "./job-list-status.html?raw";
import * as style from "./job-list-status.scss";

export default {
	viewModel: JobListStatusWidgetViewModel,
	template,
	style
};
