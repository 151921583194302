import template from "./checkbox.html?raw";
import * as style from "./checkbox.scss";

function CheckboxViewModel(params) {
    var self = this;

    // Parameter
    self.checked = params.checked;
    self.text = params.text;

    // Aktionen
    self.toggle = function () {
        var x = !Boolean(self.checked());
        self.checked(x);
    }
    self.onKeyDown = function (vm, event) {

        // Tab / Enter
        if (event.keyCode == 9 || event.keyCode == 13) {
            return true;
        }

        // Space
        if (event.keyCode == 32) {
            self.toggle();
            return false;
        }

    }
};

export default {
    viewModel: CheckboxViewModel,
    template,
    style
};