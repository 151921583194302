

function JSExtensionsString() {

	if (typeof String.prototype.startsWith != 'function') {
		String.prototype.startsWith = function (str) {
			return this.slice(0, str.length) == str;
		};
	}

	if (typeof String.prototype.endsWith != 'function') {
		String.prototype.endsWith = function (str) {
			return this.slice(-str.length) == str;
		};
	}

	if (typeof String.prototype.contains != 'function') {
		String.prototype.contains = function (str) {
			return (this.indexOf(str) >= 0);
		};
	}

	if (typeof String.prototype.count != 'function') {
		// http://stackoverflow.com/a/21730166/1501375
		String.prototype.count = function (str) {
			var counter = 0;
			for (var i = 0; i < this.length; i++) {
				var index_of_sub = this.indexOf(str, i);
				if (index_of_sub > -1) {
					counter++;
					i = index_of_sub;
				}
			}
			return counter;
		};
	}

}

JSExtensionsString();
