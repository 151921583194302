import template from "./overlay.html?raw";
import * as style from "./overlay.scss";

function OverlayViewModel(params) {
	var self = this;
	self.onCancel = params.onCancel;
}

export default {
	viewModel: OverlayViewModel,
	template,
	style
};