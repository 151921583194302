import axios from "axios"; 

export const axiosClient = axios.create();

export let fnAjax = function (url, params, onError, onSuccess) {
    axiosClient
        .post(url, params)
        .then(response => {
            if (response.status == 200)
                onSuccess(response.data);
            else
                onError({
                    code: response.status,
                    text: response.statusText
                })
        })
        .catch(error => {
            onError(error)
        });
};

export let fnDownloadFile = function(url, params, onError, onSuccess) {
    axiosClient
        .post(url, params, { responseType: 'blob'})
        .then(response => {
            var link = document.createElement('a');
            link.href = URL.createObjectURL(response.data);
            link.download = params.fileName;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            // link.click();
            onSuccess();
        })
        .catch(function (error) {
            console.log('There has been a problem with your fetch operation: ', error.message);
            onError(error);
        });
};