import { fnAjax, fnDownloadFile } from "@/services/backendAjaxService.js";

var idCheckRegEx = /[^0-9a-zA-Z]/g;
export var fnSanitizeId = function (id) {
    return String(id).replace(idCheckRegEx, "");
};

let prepareProcessJobParams = function (params) {

    // Check Params
    if (params.sourceColumnCaptions == null)
        params.sourceColumnCaptions = [];

    // Check Row Params
    for (var i = params.inputLines.length - 1; i >= 0; i--) {

        let currentLine = params.inputLines[i];

        // Check Input ID's to be Alphanumeric Only
        currentLine.id = fnSanitizeId(currentLine.id);

        // Remove empty IDs
        if (currentLine.id == null || currentLine.id == "") {
            params.inputLines.splice(i, 1);
            continue;
        }

        // Take care about Null Values in sourceColumns
        if (currentLine.sourceColumns == null)
            currentLine.sourceColumns = [];
        else
            for (var j = 0; j < currentLine.sourceColumns.length; j++) {
                if (currentLine.sourceColumns[j] == null) currentLine.sourceColumns[j] = "";
            }

    }
}

export let processJob = function (params, onError, onSuccess) {
    prepareProcessJobParams(params);
    fnAjax("api/v2/liste/processJob", params, onError, function (data) {
        if (data) {
            onSuccess(data);
        } else {
            onError();
        }
    });
};

export let processJobAsync = function (params, onError, onSuccess) {
    prepareProcessJobParams(params);
    fnAjax("api/v2/liste/processJobAsync", params, onError, onSuccess);
};

export let listStoredJobs = function (params, onError, onSuccess) {
    fnAjax("api/v2/liste/listStoredJobs", params, onError, function (data) {
        if (data) {
            onSuccess(data);
        } else {
            onError();
        }
    });
};

export let deleteStoredJob = function (params, onError, onSuccess) {
    fnAjax("api/v2/liste/deleteStoredJob", params, onError, onSuccess);
};

export let getStoredJobFile = function (params, onError, onSuccess) {
    fnDownloadFile("/api/v2/liste/getStoredJobFile", params, onError, onSuccess);
};

export let backendService = {
    processJob,
    processJobAsync,
    listStoredJobs,
    deleteStoredJob,
    getStoredJobFile
}