import ko from "knockout";
import $ from "jquery";

// Load JS Extensions
import "@/helper/jsExtensions/Array";
import "@/helper/jsExtensions/Location";
import "@/helper/jsExtensions/String";

// Register all Components and Widgets
import MainView from "@/components/pfast-main/main.js";
ko.components.register('pfast-main', MainView);

import JobListView from "@/components/pfast-view-job-list/view-job-list.js";
ko.components.register('pfast-view-job-list', JobListView);

import OverlayWidget from "@/components/widgets/pfast-overlay/overlay.js";
ko.components.register('pfast-overlay', OverlayWidget);

import MessageBoxWidget from "@/components/widgets/pfast-messagebox/messagebox.js";
ko.components.register('pfast-messagebox', MessageBoxWidget);

import IconWidget from "@/components/widgets/pfast-icons/icons.js";
ko.components.register('pfast-icon', IconWidget);

import FloatingButtonWidget from "@/components/widgets/pfast-floating-button/floating-button.js";
ko.components.register('pfast-floating-button', FloatingButtonWidget);

import CheckboxWidget from "@/components/widgets/pfast-checkbox/checkbox.js";
ko.components.register('pfast-checkbox', CheckboxWidget);

import { init as initAuth } from "@/services/auth";
await initAuth();

// Register Custom Bindings and Extenders
ko.extenders.regExAllowedPattern = function (target, regExAllowedPattern) {
	return ko.pureComputed({
		read: target,
		write: function (newValue) {
			var current = target();
			var valueToWrite = newValue.match(regExAllowedPattern);
			if (valueToWrite && valueToWrite.length > 0 && current != valueToWrite) {
				target(valueToWrite[0]);
			} else {
				target.notifySubscribers(current);
			}
		}
	}).extend({ notify: 'always' });
};
ko.extenders.numericFormat = function (target, options) {
	return ko.pureComputed({
		read: function () {

			// Options
			var numDecimalPlaces = options && options.numDecimalPlaces && !isNaN(options.numDecimalPlaces) ? options.numDecimalPlaces : 0;
			var showThousandSeperator = Boolean(options && options.showThousandSeperator);

			// Current Value
			var current = target();
			if (current == null) return null;
			if (isNaN(current)) return null;

			// Format
			var currentFormated = current.toFixed(numDecimalPlaces);
			if (numDecimalPlaces > 0)
				currentFormated = currentFormated.replace(".", ",");
			if (showThousandSeperator == true)
				currentFormated = currentFormated.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

			return currentFormated;
		}
	});
};
ko.extenders.dateFormat = function (target, options) {
	return ko.pureComputed({
		read: function () {

			// Options

			// Current Value
			var current = target();
			if (current == null || current.length < 14) return null;

			// Format
			var currentFormated = current.substr(6, 2) + "." +
				current.substr(4, 2) + "." +
				current.substr(0, 4) + " " +
				current.substr(9, 2) + ":" +
				current.substr(11, 2) + ":" +
				current.substr(13, 2);

			return currentFormated;
		}
	});
};
ko.bindingHandlers.enterkey = {
	init: function (element, valueAccessor, allBindings, viewModel) {
		var callback = valueAccessor();
		$(element).keypress(function (event) {
			var keyCode = (event.which ? event.which : event.keyCode);
			if (keyCode === 13) {
				callback.call(viewModel);
				return false;
			}
			return true;
		});
	}
};

// Apply Bindings 
ko.applyBindings();

