import $ from "jquery";

var LocalUserSettings = function () {
    var self = this;
    var dataKey = "localUserSettings";

    // Data
    self.singleJob = null;
    self.listJob = null;

    // Functions
    self.addSingleJobListItem = function (itemId) {
        self.singleJob.jobList.push({
            id: itemId
        });
    };
    self.getSingleJobList = function () {
        return self.singleJob.jobList;
    };
    self.clearSingleJobList = function () {
        self.singleJob.jobList = [];
    }

    self.getSingleJobDefaultQueryTypeId = function () {
        return self.singleJob.defaultQueryTypeId;
    };
    self.getSingleJobDefaultIdType = function () {
        return self.singleJob.defaultIdType;
    };
    self.getListJobDefaultQueryTypeId = function () {
        return self.listJob.defaultQueryTypeId;
    };

    self.setSingleJobDefaultQueryTypeId = function (newValue) {
        self.singleJob.defaultQueryTypeId = newValue;
    };
    self.setSingleJobDefaultIdType = function (newValue) {
        self.singleJob.defaultIdType = newValue;
    };
    self.setListJobDefaultQueryTypeId = function (newValue) {
        self.listJob.defaultQueryTypeId = newValue;
    };

    // Persistance    
    self.save = function () {
        var data = {
            singleJob: self.singleJob,
            listJob: self.listJob
        };
        data = JSON.stringify(data);
        localStorage.setItem(dataKey, data);
    }
    self.load = function () {
        var data = localStorage.getItem(dataKey);
        data = JSON.parse(data);
        self.singleJob = {};
        self.listJob = {};
        $.extend(self.singleJob, {
            jobList: [],
            defaultQueryTypeId: null,
            defaultIdType: null
        }, data != null ? data.singleJob : null);
        $.extend(self.listJob, {
            defaultQueryTypeId: null,
        }, data != null ? data.listJob : null);
    }
    self.load();
}

// Singleton
export let localUserSettings = new LocalUserSettings();
