import ko from "knockout";


import MainMenu from "./pfast-main-menu/main-menu.js";
ko.components.register('pfast-main-menu', MainMenu);

import MainUpdate from "./pfast-main-update/main-update.js";
ko.components.register('pfast-main-update', MainUpdate);

import MainError from "./pfast-main-error/main-error.js";
ko.components.register('pfast-main-error', MainError);

function MainViewModel(params) {

}

import template from "./main.html?raw";
import * as style from "./main.scss";

export default {
    viewModel: MainViewModel,
    template,
    style
};
