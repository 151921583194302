import template from "./icons.html?raw";

export default {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            let IconViewModel = function(params) {
                if (!params || !params.type)
                    throw ("params.type expected")
                this.type = params.type.toLowerCase();
                componentInfo.element.className += " " + this.type;
            }
            return new IconViewModel(params);
        }
    },
    template
};