

function JSExtensionsArray() {

	if (typeof Array.prototype.contains != 'function') {
		// http://stackoverflow.com/a/237176/1501375
		Array.prototype.contains = function (obj) {
			var i = this.length;
			while (i--) {
				if (this[i] === obj) {
					return true;
				}
			}
			return false;
		}
	}

	if (typeof Array.prototype.remove != 'function') {
		// http://stackoverflow.com/a/3955096/1501375
		Array.prototype.remove = function () {
			var what, a = arguments, L = a.length, ax;
			while (L && this.length) {
				what = a[--L];
				while ((ax = this.indexOf(what)) !== -1) {
					this.splice(ax, 1);
				}
			}
			return this;
		};
	}

	if (typeof Array.prototype.last != 'function') {
		// http://stackoverflow.com/a/9050354/1501375
		Array.prototype.last = function () {
			if (this.length > 0)
				return this[this.length - 1];
			else
				return null;
		};
	};

	if (typeof Array.prototype.first != 'function') {
		Array.prototype.first = function () {
			if (this.length > 0)
				return this[0];
			else
				return null;
		};
	};

}

JSExtensionsArray();

